import React, {useState, useEffect} from 'react'
import './Reservations.scss'
import AutoMenu from '../../react/AutoMenu'
import {Link, Redirect, withRouter} from 'react-router-dom'
import { useForm } from "react-hook-form";
import axios from 'axios';

// import slots from './slots'


// 5:00pm, 5:15pm




function Reservation ({history, match}) {
  const { register, handleSubmit, watch, errors } = useForm();
  const [errorMsg, setErrorMsg] = useState('')
  const [slots, setSlots]= useState([])
  const [appState, setAppState]= useState('SLOTS')
  const [selectedSlotId, setSelectedSlotId]= useState(false)
  const params = match.params



  return(
    <div className="page">

      <div className="reservations-panel">
        <div className="header">
          <h1>Reservations</h1>
  

          


          <p>Online reservations closed for today</p>

          <p>Call (213) 466-2820 for availability</p>

          <p>Check back later or join our <Link to="/order">Mailing List</Link> for Saturday, Sunday reservations</p>

          {/* <p><Link to="/order">Mailing List</Link> for future reservations and other promotions</p> */}
  
        </div>
        
      

      </div>

      
    </div>
  )
}

export default withRouter(Reservation)